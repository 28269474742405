import revive_payload_client_pRFZcsg2V0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Gdo8eMaTkF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ydZzQuchiP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UJCPCkIYMt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_la2OKnrzk6 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_XlBB7oV6ef from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.5.3_vue@3.4.31_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/apps/spa/.nuxt/components.plugin.mjs";
import prefetch_client_Eae711azSt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MNFKcabFw6 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@_n3l2uk6w7vtnjpvxf6mssnd46a/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_FL1T3ppFDo from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.18.0_vue@3.4.31_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import http_plugin_nuxt_pZ3B3eYWFB from "/opt/build/repo/node_modules/.pnpm/@nuxt-alt+http@1.7.10_nuxi@3.11.1_rollup@4.18.0_sass@1.77.6_typescript@5.5.3_vue-tsc@1.8.27_typescript@5.5.3_/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/opt/build/repo/apps/spa/.nuxt/auth.plugin.mjs";
import chunk_reload_client_sKJ1d56TZT from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_7kpuky5p5bwfqyeqqhw2bn3sb4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import FBPixel_pfxXiHSWff from "/opt/build/repo/apps/spa/plugins/FBPixel.ts";
import apollo_client_VTaW5dp4nd from "/opt/build/repo/apps/spa/plugins/apollo-client.ts";
import changeCountry_q2Al21Mu2F from "/opt/build/repo/apps/spa/plugins/changeCountry.ts";
import hotjar_pBWYs5JQbk from "/opt/build/repo/apps/spa/plugins/hotjar.ts";
import importPlugins_dSrmlJHrsT from "/opt/build/repo/apps/spa/plugins/importPlugins.ts";
import intercom_tYaoKVt49Q from "/opt/build/repo/apps/spa/plugins/intercom.ts";
import mixpanel_vYbGOFriae from "/opt/build/repo/apps/spa/plugins/mixpanel.ts";
import navigation_QptjALbk5P from "/opt/build/repo/apps/spa/plugins/navigation.ts";
import setCountry_56dWCNFicO from "/opt/build/repo/apps/spa/plugins/setCountry.ts";
import setUser_7ED3DT1SjL from "/opt/build/repo/apps/spa/plugins/setUser.ts";
import userSnap_JoYSL8axbe from "/opt/build/repo/apps/spa/plugins/userSnap.ts";
import validationFields_ioW4FysPzn from "/opt/build/repo/apps/spa/plugins/validationFields.ts";
export default [
  revive_payload_client_pRFZcsg2V0,
  unhead_Gdo8eMaTkF,
  router_ydZzQuchiP,
  payload_client_UJCPCkIYMt,
  check_outdated_build_client_la2OKnrzk6,
  plugin_vue3_XlBB7oV6ef,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Eae711azSt,
  plugin_MNFKcabFw6,
  i18n_FL1T3ppFDo,
  http_plugin_nuxt_pZ3B3eYWFB,
  auth_plugin_6DDHMgc34l,
  chunk_reload_client_sKJ1d56TZT,
  FBPixel_pfxXiHSWff,
  apollo_client_VTaW5dp4nd,
  changeCountry_q2Al21Mu2F,
  hotjar_pBWYs5JQbk,
  importPlugins_dSrmlJHrsT,
  intercom_tYaoKVt49Q,
  mixpanel_vYbGOFriae,
  navigation_QptjALbk5P,
  setCountry_56dWCNFicO,
  setUser_7ED3DT1SjL,
  userSnap_JoYSL8axbe,
  validationFields_ioW4FysPzn
]